.segmented-control {
  display: flex;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 1;
  margin: 0 auto;
  font-size: rem(16);
  font-weight: 400;
  line-height: rem(19);
  border: 1px solid $white;
  color: $transparent-grey;

  > input {
    display: none;

    &:checked + label {
      color: $black;
      font-weight: 700;
    }

    &:nth-of-type(1):checked ~ label:last-of-type::before {
      transform: translateX(0);
    }

    &:nth-of-type(2):checked ~ label:last-of-type::before {
      transform: translateX(100%);
    }
  }

  label {
    flex: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    padding: rem(10);
    transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);

    &:last-of-type::before {
      content: "";
      display: block;
      max-width: 50%;
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transform: translateX(0);
    }

    &::before {
      background: $white;
      transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
    }

    &:not(:last-child) {
      border-right: 1px solid $white;
    }
  }
}
