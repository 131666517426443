.sign-up {
  padding-top: $spacing-xs;

  &__bottom {
    width: 100%;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: $spacing-m 0 $spacing-l 0;
    &::before {
      content: "";
      position: absolute;
      width: calc(100% - 60px);
      top: 0;
      left: $spacing-horizontal;
      border-top: 1px solid rgba(151, 151, 151, 0.5);
    }

    a {
      font-size: inherit;
      line-height: inherit;
    }
  }
}
