.article-page {
  background: $white;
  color: $black;
  min-height: 100vh;

  &__wrapper {
    padding: rem($spacing-vertical-s) rem($spacing-horizontal) rem($spacing-vertical-m);
    &--flex-main {
      flex: 1;
    }
  }

  &__img {
    img {
      max-width: 100%;
      height: auto;
    }
    margin-bottom: rem($spacing-vertical-m);
  }

  &__info {
    margin: rem(-$spacing) 0 rem($spacing-half-s) 0;
    p {
      font-weight: bold;
      span {
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }

  &--flex {
    display: flex;
    flex-direction: column;
  }

  &--lg-margin-top {
    margin-top: $spacing;
  }
}
