.TradeClubCard {
  margin-left: rem(30);
  margin-right: rem(30);
  margin-top: rem(24);
  margin-bottom: rem($spacing-xl);

  &__wrapper {
    padding-top: rem(16);
    padding-right: rem(16);
    padding-left: rem(16);
    padding-bottom: rem(24);
    background: linear-gradient(155.26deg, $dark-grey, $black);
    position: relative;

    &__close {
      position: absolute;
      right: rem(16);
      top: rem(8);

      > * {
        font-weight: normal;
      }
    }
    &__close-btn {
      border: 0;
      background: transparent;
      margin: 0;
      padding: 0;
    }
  }

  &__heading {
    margin-bottom: rem(8);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__text {
      font-style: normal;
      background: $black;
      display: inline-block;
      font-size: rem(28);
      line-height: rem(27);
      font-weight: 700;
      color: $white;
      margin: 0;
      padding: rem(4);
    }
  }

  &__description {
    margin-bottom: rem(8);

    &__text {
      font-style: normal;
      font-weight: normal;
      font-size: rem(12);
      line-height: rem(17);
      color: $white;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    &__cta-btn {
      a {
        font-size: rem(14);
        padding: rem(8.5) rem(8);
      }
    }
  }
}
