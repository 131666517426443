//
// -------------------------------------
// 	USAGE
// -------------------------------------
//  some usefull information for developer to help describe
//  what following classes do.
// .head-page--white - Heading white variant
// .head-page--black - Heading black variant

$name: head-page;
$selector: ".#{$name}";

#{$selector} {
  padding: $spacing-vertical-s $spacing-horizontal $spacing-vertical-m;

  &--white,
  &--black {
    #{$selector} {
      &__title {
        * {
          @extend .h3;
          margin: 0;
        }
      }
      &__description {
        padding: rem($spacing) 0 0 0;
        * {
          @extend .p;
        }
      }
    }
  }

  &--white {
    #{$selector} {
      &__title {
        padding: 0;
        * {
          @extend .white-highlight;
        }
      }
    }
  }

  &--black {
    #{$selector} {
      &__title {
        * {
          @extend .black-highlight;
        }
      }
    }
  }
  &--yellow {
    #{$selector} {
      &__title {
        * {
          @extend .yellow-highlight;
        }
      }
    }
  }

  &__back-btn {
    background: none;
    border: none;
    text-transform: uppercase;
    font-size: rem(16);
    line-height: rem(16);
    padding: 0;
  }

  &__title {
    padding-top: rem(8);
    * {
      margin: 0;
      font-size: rem(28);
      line-height: rem(28);
    }
    &--heading {
      * {
        font-size: rem(32);
        line-height: rem(32);
      }
    }
  }

  &__description {
    padding-top: rem(8);
    * {
      margin: 0;
    }
  }

  &__nav {
    padding: rem($spacing-vertical-s) rem($spacing-horizontal) rem($spacing-vertical-m);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem($spacing-xs);
    .head-page {
      padding: 0;
    }

    &__right-btn {
      display: inline-block;
      border-radius: rem(3);
      color: $black;
      border: 0;
      padding: rem(4);
      i {
        font-size: rem(28);
        color: $yellow;
      }
    }

    &__btns {
      > * {
        margin-left: rem(10);
      }
    }
  }

  .small-text {
    &--bold {
      font-size: rem(12);
      font-weight: bold;
      font-family: $font-regular;
      text-transform: uppercase;
    }
  }
}
