.text-link {
  display: inline-block;
  font-size: rem(16);
  line-height: rem(18);
  margin: 0 0 rem(8) 0;
  color: $yellow;
  &--uppercase {
    text-decoration: underline;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline;
    }
  }
  &--none {
    text-transform: none;
  }
}
