.store-search {
  &__search-input {
    position: relative;
    .fa-location {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: rem(12px);
      font-size: rem(20);
    }
    input {
      background-color: rgba(255, 255, 255, 0.1);
      color: $white;
      height: rem(50);
      padding: 0 0 0 rem(42);
      border: 0;
    }
  }
  &__search-results {
    padding: 0;
    margin: rem($spacing-s) 0 0  0;
    list-style: none;
    li {
      padding-top: rem($spacing-s);
      border-top: 1px solid $dark-grey;
      .store-name {
        margin-bottom: rem($spacing-xxs);
      }
      .store-address {
        margin: 0 0 rem($spacing-s) 0;
      }
    }
  }
}
