/**
 * Media query breakpoints, simplified.
 */
// @mixin breakpoint($point) {
//   @each $breakpoint in map-keys($breakpoints) {
//     @if $point == $breakpoint {
//       @media (min-width: map-get($breakpoints, $breakpoint)) {
//         @content;
//       }
//     }
//   }
// }

// @mixin breakpoint-reverse($point) {
//   @each $breakpoint in map-keys($breakpoints) {
//     @if $point == $breakpoint {
//       @media (max-width: map-get($breakpoints, $breakpoint)) {
//         @content;
//       }
//     }
//   }
// }

@mixin wh($width, $height: $width) {
  height: $height;
  width: $width;
}

/**
 * Define hover state as well as focus and active by default.
 * You can mix the states that you wish to use.
 */
@mixin hover($hover: true, $focus: true, $active: true) {
  // Prefill the states list with the options.
  $states: ();
  @if $hover {
    $states: append($states, hover);
  }
  @if $focus {
    $states: append($states, focus);
  }
  @if $active {
    $states: append($states, active);
  }

  // Generate the CSS selector based on the options above.
  $selector: "";
  @each $state in $states {
    $selector: $selector + "&:" + $state + ",";
  }

  // Remove the last comma.
  $selector: str-slice($selector, 0, -2);

  // Output the CSS rule.
  #{$selector} {
    @content;
  }
}

/**
 * Defines font-size and line-heights for various breakpoints.
 *
 * @requires 'rem' function from Bourbon.io
 * @requires 'breakpoint' function from _grid.scss from this repo.
 *
 * Parameters:
 *    first:  breakpoint name
 *    second: font-size (without unit)
 *    third:  line-height (without unit)
 *
 * @example
 *     @include responsive-font-sizes(breakpoint font-size line-height, breakpoint font-size line-height...);
 *     @include responsive-font-sizes(none 16 26, sm 24 30, md 34 42);
 */
@mixin responsive-font-sizes($sizes...) {
  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);
    $breakpoint: nth($size, 1);
    $font-size: nth($size, 2);
    $line-height: nth($size, 3);
    @if ($breakpoint == none) {
      @include font-size($font-size);
      line-height: em($line-height, $font-size);
    } @else {
      @include breakpoint($breakpoint) {
        @include font-size($font-size);
        line-height: em($line-height, $font-size);
      }
    }
  }
}

/* Usage: @include font-family(font-normal) */
@mixin font-family($family) {
  $font: map-get($font-face, $family);
  font-family: map-get($font, family), $font-stack;
  font-weight: map-get($font, weight);
  font-style: map-get($font, style);
}

@mixin font-size($size) {
  font-size: strip-unit($size) * 1px;
  font-size: rem($size);
}

/* Font settings and sizing for RTE */
@mixin rte-font-settings($isHeadings: false) {
  font-family: $font-stack;
  line-height: 1em;
  color: #000000;
  @if ($isHeadings) {
    @include font-family("font-bold");
    margin: 0 0 0.5em;
    color: #000000;
  } @else {
    @include font-family("font-normal");
  }
}

/* Force scrollbar visible on webkit */
@mixin touchscroll-visible() {
  &::-webkit-scrollbar {
    appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 1px solid transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #d3d3d3;
  }
}

/*! Wrap word */
@mixin word-wrap($wrap: break-word) {
  word-wrap: $wrap;
  @if $wrap == break-word {
    overflow-wrap: break-word;
    word-break: break-word;
    word-break: break-all;
  }
}

@mixin transition($transition...) {
  transition: $transition;
}

/*! button border gradient */
@mixin button-border-gradient($color1, $color2) {
  border-image: linear-gradient($color1, $color2);
}

@mixin grid-container($is-padded: false) {
  margin: 0 auto;
  @if $is-padded {
    max-width: map-get($global-layouts, 'container-width') + (2 * map-get($spacing, 'grid-gutter'));
  } @else {
    max-width: map-get($global-layouts, 'container-width');
  }
  padding: 0;
}

@mixin display($position) {
  @if $position == Justify-center {
    display: flex;
    justify-content: center;
  } @else {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin max-width() {
  max-width: 1170px;
  margin: 0 auto;
}

@mixin corner-shape($width, $height, $color) {
  width: 0;
  height: 0;
  border-bottom: rem($height) solid $color;
  border-left: rem($width) solid transparent;
}

@mixin sliderProgressFill($no) {
  @for $i from 1 through $no {
    &:nth-of-type(#{$i}) {
      &.slick-active {
        width: calc((100% / #{$no}) * #{$i});
      }
    }
  }
}
