// Be careful using vairables here
// this is shared by other teams without the same variable names
.ks-header {
  padding: rem(40) 0 rem(20) 0;
  h1 {
    color: #fff200;
  }
  h3 {
    margin-top: rem(10);
  }
}

.ks-body {
  padding: rem(32) rem(16);
}

.ks-sidebar {
  display: none;
  background-color: #ffffff;
  box-shadow: 0 rem(14) rem(28) transparentize($color: #1d262d, $amount: 0.75), 0 rem(10) rem(10) transparentize($color: #1d262d, $amount: 0.75);
  position: fixed;
  padding: rem(20);
  padding-left: rem(50);
  min-height: 100%;
  min-width: rem(300);
  width: rem(400);
  z-index: 999;
  right: 0;

  a {
    text-decoration: none;
  }
}

.ks-sidebar__header {
  color: #fff200;
  margin-bottom: rem(20);
}

.ks-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: rem(25);
  height: rem(45);
  right: rem(20);
  top: rem(30);
  width: rem(45);

  &.ks-menu {
    background-color: #fff200;
    border-radius: 50%;
    color: #ffffff;
    position: fixed;
    z-index: 999;
  }

  &.ks-close {
    color: #fff200;
    position: absolute;
  }
}

.ks-section {
  padding: rem(40) 0;
  padding-bottom: 0;

  &:first-of-type {
    padding-top: rem(20);
  }
}

.ks-title {
  color: $yellow;
  font-size: rem(20);
  font-weight: bold;
  letter-spacing: rem(3);
  margin-bottom: rem(40);
  text-transform: uppercase;
}

.ks-subtitle {
  color: $yellow;
  font-size: rem(16);
  margin-bottom: rem(20);
  margin-top: rem(40);
  text-transform: uppercase;
}

.ks-divider {
  border: none;
  border-bottom: rem(1) solid #5d5d5d;
  margin: 0;
  margin-top: rem(40);
}
