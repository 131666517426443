.activity-log-card {
  margin-top: $spacing-vertical-m;
  padding: $spacing-s;
  background: linear-gradient(155.26deg, #333333 8.34%, #151515 105.64%);
  display: flex;
  justify-content: space-between;
  position: relative;
  &__title {
    margin: 0;
  }

  &__score {
    padding-left: $spacing-m;
    padding-right: rem(6);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__number {
      font-size: rem(32);
      line-height: rem(32);
      font-weight: bold;
    }

    &__date {
      font-size: rem(10);
      line-height: rem(11);
    }

    &__points {
      font-size: rem(10);
      line-height: rem(14);
    }
  }

  &__description {
    color: $transparent-white;
    margin-top: rem($spacing-xs);
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 0;
    right: 0;
    @include corner-shape(18, 35, $yellow);
  }
}
