.account {
  h4 {
    text-transform: none;
  }
  text-transform: lowercase;
  &__wrapper {
    margin: rem(15) rem(30);
  }
  &__heading {
    .head-page {
      padding-left: 0;
      &__title {
        h1 {
          font-size: rem(32);
          line-height: rem(32);
          font-weight: 700;
        }
      }
    }
  }

  &__details {
    border-top: 1px solid $dark-grey;
    margin-top: rem($spacing-xs);
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      font-weight: 700;
    }
    &--icon {
      margin-right: rem(16);
      .fas {
        font-size: rem(16);
        font-weight: 900;
        line-height: rem(19);
      }
    }
    &--right-arrow {
      font-size: rem(18);
    }
    padding: rem(16) 0;
    &__wrapper {
      display: flex;
      align-items: center;
    }
  }
  &__lbp-details {
    border-top: 1px solid #333333;
    display: flex;
    justify-content: space-between;
    h4 {
      margin: 0;
      font-weight: 700;
    }
    &--icon {
      margin-right: rem(16);
      .fas {
        font-size: rem(16);
        font-weight: 900;
        line-height: rem(19);
      }
    }
    &--right-arrow {
      font-size: rem(18);
    }
    padding: rem(16) 0;
    &__wrapper {
      display: flex;
      align-items: center;
    }
  }
  &__remove_account {
    a{
      color: #fff200;
      text-decoration: underline;
      line-height: 4;
      cursor:pointer;
    }
    
  }
  &__logout {
    position: absolute;
    bottom: rem(84);
    i {
      color: $yellow;
      font-weight: 900;
      font-size: rem(16);
      line-height: rem(19);
    }
    a {
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--btn {
      background: transparent;
      color: $yellow;
      border: 0;
      font-weight: 400;
      font-size: rem(12);
      line-height: rem(16);
      text-decoration: underline;
      padding-left: rem(5);
    }
  }
  &__priority {
    border-top: 1px solid #333333;
    display: flex;
    justify-content: space-between;
    h4 {
      margin: 0;
      font-weight: 700;
    }
    &--icon {
      margin-right: rem(16);
      .fas {
        font-size: rem(16);
        font-weight: 900;
        line-height: rem(19);
      }
    }
    &--right-arrow {
      font-size: rem(18);
    }
    padding: rem(16) 0;
    &__wrapper {
      display: flex;
      align-items: center;
    }
  }
  &__trade {
    border-top: 1px solid #333333;
    display: flex;
    justify-content: space-between;
    h4 {
      margin: 0;
      font-weight: 700;
    }
    &--icon {
      margin-right: rem(16);
      .fas {
        font-size: rem(16);
        font-weight: 900;
        line-height: rem(19);
      }
    }
    &--right-arrow {
      font-size: rem(18);
    }
    padding: rem(16) 0;
    &__wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__local-itm {
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
    display: flex;
    justify-content: space-between;
    h4 {
      margin: 0;
      font-weight: 700;
    }
    &--icon {
      margin-right: rem(16);
      i {
        font-weight: 900;
        font-size: rem(16);
        line-height: rem(20);
      }
      .far {
        font-size: rem(16);
        font-weight: 900;
        line-height: rem(19);
      }
    }
    &--right-arrow {
      font-size: rem(18);
    }
    padding: rem(16) 0;
    &__wrapper {
      display: flex;
      align-items: center;
    }
    &--text {
      p {
        margin-top: rem(4);
        font-size: rem(12);
        line-height: rem(12);
        font-weight: 400;
      }
    }
  }
}
