//
// -------------------------------------
// 	USAGE
// -------------------------------------
//  some usefull information for developer to help describe
//  what following classes do.
// .featured-card--promotional - Promotional news cards variant
// .featured-card--news - Featured news card variant

$name: featured-card;
$selector: ".#{$name}";

#{$selector} {
  padding: 0 0 rem($spacing) 0;
  &__wrapper {
    position: relative;
  }

  &__img {
    height: 250px;
    position: relative;
  }

  // .featured-card--news
  &--news {
    #{$selector} {
      &__content {
        position: absolute;
        inset: auto rem($spacing-m) rem($spacing-s);
      }
      &__img {
        &::after {
          content: "";
          position: absolute;
          inset: 0;
          background: linear-gradient(180deg, rgba(12, 12, 12, 0) 0%, rgba(12, 12, 12, 0.9) 100%);
        }
      }
      &__heading {
        h4 {
          margin: 0 0 rem($spacing-xxs) 0;
        }
      }
      &__description {
        p {
          margin: 0;
        }
      }
    }
  }

  // .featured-card--promotional
  &--promotional {
    #{$selector} {
      &__content {
        padding: rem($spacing-s) rem($spacing-m) 0;
      }
      &__heading {
        h3 {
          margin: 0 0 rem($spacing-xs) 0;
        }
      }
    }
  }
}
