.lbp-licence {
  &__heading {
    margin-top: rem($spacing-xxs);
  }
  &__menu-items {
    padding: 0 rem(30);
    p {
      margin: 0;
    }
    &--elective {
      &--text {
        display: flex;
        justify-content: space-between;
        padding: rem(15) 0 0;
      }
    }
    &--learning {
      &--text {
        display: flex;
        justify-content: space-between;
        padding: rem(15) 0 0;
      }
    }
    &--codewords {
      &-text {
        display: flex;
        justify-content: space-between;
        padding: rem(15) 0 0;
      }
    }
  }
  &__progressbar {
    background-color: $black;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    display: flex;
    // align-items: center;
    justify-content: center;
    // height: 100vh;
    margin: 0;
    width: 100%;

    .progress {
      background-color: rgba(255, 242, 0, 0.2);
      border-radius: 20px;
      position: relative;
      margin: 15px 0;
      height: 2px;
      width: 100%;
    }

    .progress-yellow {
      background: $yellow;
      box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c;
      border-radius: 20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 0;
      opacity: 0;
      transition: 1s ease 0.3s;
    }
    .progress-orange {
      background: #ff4f00;
      box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c;
      border-radius: 20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 0;
      opacity: 0;
      transition: 1s ease 0.3s;
    }
    .progress-red {
      background: #ffa500;
      box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c;
      border-radius: 20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 0;
      opacity: 0;
      transition: 1s ease 0.3s;
    }
  }
  &__expiry {
    display: flex;
    background: linear-gradient(0deg, rgba(92, 92, 92, 0.2), rgba(92, 92, 92, 0.2));
    padding-left: rem(15);
    padding-right: rem(11);
    padding-top: rem(9);
    padding-bottom: rem(9);
    margin-bottom: rem($spacing-xl);
    position: relative;
    &-icon {
      margin-right: rem(11);
      i {
        font-weight: 900;
        font-size: rem(20);
        line-height: rem(20);
      }
    }
    &-text {
      margin: 0;
      h4 {
        margin: 0;
      }
    }
    &-btn {
      padding-left: rem(9);
      border: 0;
      background: transparent;
      position: absolute;
      right: 0;
      i {
        font-weight: 400;
        font-size: rem(18);
        line-height: rem(18);
      }
    }
  }
}
