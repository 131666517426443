

.priority-card-digital {
  margin-left: rem(30);
  margin-right: rem(30);
  margin-bottom: rem($spacing-xl);

  &__wrapper {
    padding-top: rem(16);
    padding-right: rem(16);
    padding-left: rem(16);
    padding-bottom: rem(16);
    background: linear-gradient(155.26deg, $dark-grey 8.34%, $eerie-black 105.64%);
    display: flex;
    justify-content: space-between;
  }

  &__heading {
    margin-bottom: rem(8);
    flex-direction: column;
    align-items: flex-start;

    &__text {
      span:first-child {
        display: inline-block;
        padding: rem(2) rem(4);
        background: $black;
      }
      span:last-child {
        padding-left: rem(4);
      }
      font-style: normal;
      background: $black;
      display: inline;
      font-size: rem(22);
      line-height: rem(22);
      font-weight: 700;
      color: $white;
      margin: 0;
      padding: rem(4) rem(4) rem(4) 0;
    }
  }

  &__description {
    margin-bottom: rem(8);

    &__text {
      font-style: normal;
      font-weight: 400;
      font-size: rem(12);
      line-height: rem(17);
      color: $white;
      text-transform: none;
    }
  }

  &__content {
    flex: 1;
  }

  &__image {
    max-width: 140px;
    flex: 1;
    svg {
      width: 100%;
      height: 100%;
      border: 2px solid rgba(228, 228, 228, 0.2);
      border-radius: rem(5);
      rect {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__image-banner {
    width: 100%;
  }

  .modal {
    &__items {
      width: 100%;
    }
    &__wrapper {
      padding: 0 rem(12);
      svg {
        width: calc(100% - #{rem(24)});
        height: auto;
      }
    }
    &__text {
      position: relative;
      left: rem(12);
    }
    &__card-img {
      svg {
        width: 100%;
        height: 100%;
        rect {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
