.check-email {
  &__form {
    margin: rem($spacing) 0 0 0;
    &__container {
      display: flex;
      justify-content: center;
      gap: rem(12);
    }
    &__dash {
      font-size: rem(42);
      @include font-family("font-bold-third");
      height: rem(54);
      position: relative;
      top: rem(-6);
    }
    input {
      width: rem(38);
      height: rem(54);
      font-size: rem(22);
      @include font-family("font-bold-third");
      text-align: center;
      padding: rem($spacing-xxs);
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    .error-message {
      padding: 0;
      margin-top: rem($spacing-s);
    }
  }
}
