.lbp-diary-action {
  background: none;
  display: block;
  width: 100%;
  border: none;
  padding: 0;

  &--top-margin {
    .lbp-diary-action {
      &:first-child {
        > div {
          margin-top: rem(-$spacing-xs);
        }
      }
    }
  }

  &__wrapper {
    padding: $spacing-s;
    border: 1px solid $yellow;
    margin: $spacing-xs $spacing-horizontal;
    position: relative;
    text-align: left;
  }

  &__title {
    margin: 0;
  }

  &__description {
    margin: rem($spacing-xxs) 0 0 0;
    color: $transparent-white;
  }

  &__corner {
    @include corner-shape(22, 51, $yellow);
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
