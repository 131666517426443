.document-library-card {
  display: flex;
  width: rem(145);
  height: rem(95);
  background-color: $white;
  position: relative;
  flex-shrink: 0;
  border-radius: 2px;

  .cards-list & {
    &:not(.document-library-card--full-width) {
      width: calc((100% / 2) - 24px);
      max-width: rem(145);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: rem(11);
  }

  &__title {
    font-size: rem(12);
    line-height: rem(14);
    color: $black;
    text-transform: uppercase;
    margin: 0;
    font-weight: 700;
    text-align: center;
  }

  &__figure {
    margin-bottom: $spacing-xs;
  }

  &__favourite {
    width: rem(37);
    height: rem(59);
    position: absolute;
    right: 0;
    bottom: 0;
    border: none;
    background: none;
    padding: 0;

    &__backdrop {
      width: 0;
      height: 0;
      border-bottom: rem(59) solid $mid-grey;
      border-left: rem(37) solid transparent;

      &--small {
        border-bottom: rem(36) solid $mid-grey;
        border-left: rem(25) solid transparent;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    &__star,
    &__star--active {
      position: absolute;
      right: rem(3);
      bottom: rem(7);
      color: white;
      opacity: 0.3;
    }
    &__star--active {
      color: $yellow;
      opacity: 1;
    }
  }

  &--full-width {
    width: 100%;
    height: rem(70);

    .document-library-card__wrapper {
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 rem(19);
    }

    .document-library-card__figure {
      margin-right: rem(19);
      margin-bottom: 0;
    }
    .document-library-card__title {
      font-size: rem(16);
      line-height: rem(19);
      font-weight: 600;
      text-align: left;
    }
  }

  .fa-file-pdf {
    color: $mid-red;
    font-size: rem(28);
  }
  .fa-file-image {
    color: $blue;
    font-size: rem(28);
  }
  .fa-file-video {
    color: $green;
    font-size: rem(28);
  }
}
