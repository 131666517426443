.PriorityCardLogin {
  position: relative;

  &__wrapper-main {
    width: 100%;
    position: absolute;
    .head-page__back-btn {
      padding-bottom: rem(24);
    }
  }

  &__wrapper-sub {
    width: 100%;
    position: fixed;
    bottom: rem(60);
    .form-button {
      padding-bottom: rem(32);
    }
  }

  &__hr {
    height: 1px;
    border: 0;
    border-top: 1px solid $mild-grey;
    margin: 0 rem(30);
    margin-bottom: rem(32);
  }

  &__bottom-text {
    margin-bottom: rem(32);

    p {
      margin: 0;
    }

    a {
      margin-left: rem(4);
      text-align: center;
      line-height: rem(23);
    }
    display: flex;
    justify-content: center;
  }
  .error-message {
    color: $light-red;
    margin-left: rem(32);
  }
}
