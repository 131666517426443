.closest-itm-store {
  &__wrapper {
    padding-bottom: rem(190);
  }
  &__title {
    margin: 0 0 rem($spacing-xs) 0;
  }
  &__img {
    img {
      width: 100%;
      height: rem(250);
    }
    margin-bottom: rem($spacing-s);
  }
  &__store-name {
    margin: 0;
    padding-bottom: rem($spacing-s);
    border-bottom: 1px solid $dark-grey;
  }
  &__address {
    display: flex;
    gap: rem($spacing-xxs);
    margin: rem($spacing-xs) 0 rem($spacing-s) 0;
    > div {
      flex-basis: 50%;
    }
  }
  &__phone-no {
    padding-bottom: rem($spacing-xs);
    border-bottom: 1px solid $dark-grey;
  }
  &__online-hours {
    margin-top: rem($spacing);

    ul {
      padding: 0;
      margin: rem($spacing-xs) 0 0 0;
      list-style: none;
      li {
        padding: rem($spacing-xs) 0;
        border-top: 1px solid $dark-grey;
        display: flex;
        justify-content: space-between;
        &:last-child {
          border-bottom: 1px solid $dark-grey;
        }
        span {
          @extend p;
          margin: 0;
        }
      }
    }
  }
  &__sticky-footer {
    background-color: $black;
  }
}
