.codewords-issue {
  padding: 0 $spacing-horizontal;
  margin-bottom: $spacing-s;
  &__wrapper {
    border: 1px solid $mid-grey;
    border-radius: 2px;
  }

  &__title {
    padding: $spacing-xs $spacing-s;
    background-color: $x-dark-grey;
    h2 {
      font-size: rem(16);
      line-height: rem(19);
      font-family: $font-regular;
      margin: 0;
      text-transform: none;
    }
  }

  &__content {
    background-color: $dark-grey;
    padding: $spacing $spacing-s;

    h3 {
      font-family: $font-regular;
      font-size: rem(12);
      line-height: rem(14);
      color: $light-grey;
      opacity: 0.7;
    }

    ul {
      list-style: none;
      li {
        margin-top: $spacing-s;
        display: flex;
        align-items: center;
        gap: rem($spacing-xs);
        a {
          font-size: rem(16);
          line-height: rem(22);
          font-family: $font-regular;
          font-weight: normal;
          text-transform: none;
          text-decoration: underline;
        }
        button {
          text-align: left;
        }
      }
    }
  }

  &__group {
    margin-top: $spacing;
    &:first-child {
      margin-top: 0;
    }
  }

  &__link {
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
  }
}
