.PriorityCardSettings {
  &__wrapper-main {
    .head-page {
      &__back-btn {
        padding-bottom: rem(24);
      }
    }
  }
  &__description-sub {
    margin-left: rem(30);
    p {
      margin: 0;
    }
    .card-number {
      font-weight: 700;
      font-size: rem(16);
      line-height: rem(23);
    }
    a {
      text-transform: lowercase;
      text-decoration: underline;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(23);
    }
  }
  p {
    font-weight: 400;
    line-height: rem(22);
  }
  &__description-main {
    margin-left: rem(30);
    margin-top: rem(8);
    margin-bottom: rem(24);
  }
}
