// Global styles.
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}
// We wish to define the font-size on the html/body elements in pixels to ensure consistency.
html,
body {
  font-family: map-get(map-get($font-face, map-get($font-config, primary-font)), family), $font-stack;
  font-size: map-get($font-config, em-base);
  line-height: map-get($font-config, line-height);
  color: $white;
  height: 100%;
  margin: 0;
}

body {
  background-color: $black;
  -webkit-overflow-scrolling: touch; // improves "lagging" on iOs
  position: relative;
}

img {
  display: inline-block;
  vertical-align: middle;
  @extend .img-responsive;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

// enable accessibility outline (tab key)
body.accessibility-keys {
  *:focus {
    outline-width: 5px !important;
    outline-color: -webkit-focus-ring-color !important;
    outline-style: auto !important;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #fff !important;
  box-shadow: 0 0 0 1000px white inset !important;
}

figcaption {
  font-size: 16px;
  margin-bottom: 12px;
}

figcaption,
pre,
code {
  font-family: monospace;
}

figure {
  margin: rem(30) 0 rem(30) 0;
  figcaption {
    @include font-family("font-normal");
    font-size: rem(14);
  }
}

pre {
  background-color: #f5f5f5;
  overflow: auto;
  padding: 0 10px;
}

pre > code {
  display: block;
  word-wrap: normal;
  font-size: rem(14);
}
