$name: trade-club-card;
$selector: ".#{$name}";

#{$selector} {
  margin: 0 rem($spacing-horizontal) 5px;
  background: $gradient-mild-grey;
  &__wrapper {
    padding: rem($spacing-s) rem($spacing-half-s) rem($spacing);
    h3 {
      margin-bottom: rem($spacing-xs);
      font-size: rem(32);
    }
  }
  &__total-points {
    p {
      margin: 0;
    }
    span {
      font-size: rem(80);
      line-height: 100%;
      letter-spacing: -1.86042px;
      font-family: $font-eurostile;
      font-weight: bold;
    }
  }
  &__monthly-points {
    display: flex;
    margin-bottom: rem($spacing-s);
    gap: 2px;
    &__earned,
    &__expiriny {
      flex-basis: 50%;
      font-size: rem(12);
      line-height: rem(14);
      span {
        display: block;
        margin-left: rem($spacing-xs);
        &:first-child {
          font-weight: bold;
          margin: 0 0 1px 0;
        }
      }
    }
    &__earned {
      color: $light-green;
    }
    &__expiriny {
      color: $light-red;
    }
  }
  &__last-updated {
    p {
      font-family: $font-regular;
      font-style: italic;
      font-size: rem(10);
      line-height: rem(14);
    }
  }
  
  &__need-help {
    
    #email{
      color: #fff200;
      text-transform: none;
      font-style: italic;
    }
    #phone{
      color: #fff200;
      font-style: italic;
    }
    p {
      font-family: $font-regular;
      font-style: italic;
      font-size: rem(10);
      line-height: rem(14);
    }
  }
  &__redemptions {
    margin: 0 rem($spacing-horizontal) rem($spacing-xl);
    padding-bottom: rem($spacing-horizontal);
    #login_redemption{
      font-size: 20px;
      color: #fff200;
      float:right;
      text-decoration: underline;
      margin-bottom: rem($spacing-horizontal);
      
    }
    p {
      font-family: $font-regular;
      font-style: italic;
      font-size: rem(10);
      line-height: rem(14);
      padding-bottom:10px;
    }
  }
}
