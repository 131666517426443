.loading {
  width: 100%;
  height: 100vh;
  display: inline-block;
  overflow: hidden;

  &__wrapper {
    div {
      position: absolute;
      width: 60px;
      height: 60px;
      border: 10px solid $yellow;
      border-top-color: transparent;
      border-radius: 50%;
      animation: loadingSpinner 1s linear infinite;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-sizing: content-box;
    }
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
}

@keyframes loadingSpinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
