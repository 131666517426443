.activity-log-add {
  background: $black;
  &__wrapper {
    padding: rem($spacing) rem($spacing-horizontal) rem($spacing-m);
  }

  &__title {
    color: $white;
  }

  &__description {
    color: $mild-grey;
    font-size: rem(12);
    line-height: rem(17);
    margin: 0;
  }

  &__btn {
    @extend .btn;
    @extend .btn--primary;
    margin-bottom: rem(9);
    margin-top: rem(24);
  }
}

