.modal {
  padding-top: 0;
  padding-bottom: rem(60);
  &__wrapper {
    width: 100%;
    padding-right: rem(30);
    padding-left: rem(30);

    svg {
      display: block;
      margin: 0 auto;
      margin-top: rem(36);
    }
  }
  &__card-img {
    svg {
      width: rem(328);
      height: rem(207);
      margin-top: rem(18);
      max-width: 100%;
    }
  }
  position: fixed;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  color: $black;
  z-index: 1;
  transition: top 0.3s;
  &.show {
    top: rem(100);
    &::before {
      content: " ";
      position: absolute;
      height: rem(100);
      width: 100%;
      top: rem(-100);
      background: rgba(0, 0, 0, 0.8);
    }
  }

  &__container {
    overflow: scroll;
    height: 100%;
  }

  &__text {
    display: inline;
    background-color: $black;
    color: $white;

    span {
      margin-right: rem(8);
      margin-left: rem(4);
    }

    h3 {
      margin-bottom: rem(20);
    }
  }
  &__close-btn {
    position: relative;
    border: 0;
    background: transparent;
    display: flex;
    margin-left: auto;
    align-items: center;
    gap: rem($spacing-xs);
    font-weight: 600;
    font-size: rem(16);
    line-height: rem(17);
    margin-top: rem(23);
    .fas {
      font-size: rem(20);
    }
  }
  &__content {
    margin-bottom: rem(20);
    text-align: center;
  }
  &__items {
    position: relative;
    display: inline-block;
    text-align: center;
  }
  &__username {
    position: absolute;
    bottom: 0;
    color: $white;
    margin-bottom: rem(10);
    margin-left: rem(96);
    margin-top: rem(15);
    h2 {
      display: inline-block;
      margin-right: rem(5);
      margin-top: rem(15);
      text-transform: uppercase;
    }
  }
  &__card-number {
    position: absolute;
    top: 50%;
    margin-top: rem(8);
    bottom: rem(43);
    right: rem(40);
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: end;
    h2 {
      margin: 0;
      margin-top: rem(4);
    }
    h6 {
      text-transform: uppercase;
      margin-bottom: 0;
      font-weight: 600;
      line-height: rem(12);
      font-size: rem(10);
    }
  }
}
