.cards-list {
  display: flex;
  padding: 0 rem(18) 0;
  flex-wrap: wrap;

  > * {
    margin: rem(12);
  }

  &--stack {
    flex-direction: column;
    padding: 0 rem(30) 0;
    > * {
      margin: 0 0 rem(8) 0;
    }
  }
}
