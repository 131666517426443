.form-preview {
  margin-top: rem($spacing-xs);

  &__bold {
    p {
      margin-top: 0;
      &.small-text {
        margin: 0 0 rem($spacing-xxs) 0;
      }
    }
    h4,
    p:not(.small-text) {
      padding-bottom: rem($spacing-s);
      border-bottom: 1px solid $dark-grey;
      margin-bottom: rem($spacing-s);
    }
  }
  &__img {
    p {
      &.small-text {
        margin-bottom: rem($spacing-xs);
      }
    }
    img {
      margin-bottom: rem($spacing-s);
      max-height: 150px;
      width: 100%;
      &:last-child {
        margin-bottom: rem($spacing-m);
      }
    }
  }
  .form-button {
    padding-bottom: rem($spacing-s);
  }
  a {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0 0 rem($spacing-xl) 0;
    text-decoration: underline;
  }
}
