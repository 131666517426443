//
// -------------------------------------
// 	USAGE
// -------------------------------------
//  some usefull information for developer to help describe
//  what following classes do.
// .listing-card--deals - Promotional card variant
// .listing-card--news - News card variant

$name: listing-card;
$selector: ".#{$name}";

#{$selector} {
  padding: rem($spacing-xs) rem($spacing-horizontal);

  &__wrapper {
    position: relative;
  }

  &__img {
    height: 200px;
    position: relative;
  }

  &--news {
    #{$selector} {
      &__img {
        &::before {
          content: "";
          position: absolute;
          inset: 0;
          background: $x-dark-grey;
          opacity: 0.7;
        }
        &::after {
          content: " ";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0;
          height: 0;
          border-bottom: 46px solid $yellow;
          border-left: 26px solid transparent;
        }
      }
      &__content {
        position: absolute;
        inset: auto rem(26) rem($spacing-s) rem($spacing-xs);
      }
      &__date {
        p {
          margin: 0 0 rem($spacing-xxs) 0;
          color: $transparent-grey;
        }
      }
      &__heading {
        h4 {
          margin: 0;
        }
      }
    }
  }
  &--deals {
    #{$selector} {
      &__content {
        background: $dark-grey;
        padding: rem(16);
        position: relative;
        &::after {
          content: " ";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0;
          height: 0;
          border-bottom: 46px solid $yellow;
          border-left: 26px solid transparent;
        }
      }
      &__heading {
        h4 {
          margin: 0;
        }
      }
    }
  }
}
