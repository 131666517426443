$name: section-header;
$selector: ".#{$name}";

#{$selector} {
  &--heading-only {
    #{$selector} {
      &__top {
        margin: 0;
      }
    }
  }
  &__wrapper {
    margin: 0 rem($spacing-horizontal);
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem($spacing-s);
    * {
      margin: 0;
    }
  }
  &__bottom {
    max-width: rem(279);
    padding-bottom: rem($spacing-xs);
    p {
      @extend .two-line-clamp;
    }
  }
}
