.hammer-nail-image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-70%, -50%);
  z-index: 5;

  &__top-icon {
    width: 100px;
    animation-name: hammerRotation;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
  }

  &__bottom-icon {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 50%;
    width: 100px;
    transform: translateX(-46%);
  }

  path {
    fill: $yellow;
  }
}

@keyframes hammerRotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(48deg);
  }
  90% {
    transform: rotate(0deg);
  }
}
