.local-itm {
  &wrapper {
    margin-right: rem(30);
  }
  &__banner {
    margin-left: rem(30);
  }
  &__sub-heading {
    margin-left: rem(30);
    padding: rem(16) 0;
    border-bottom: 1px solid $dark-grey;
    h3 {
      margin: 0;
    }
  }
  &__heading {
    .head-page__back-btn {
      padding-bottom: rem(24);
    }
  }
  &__address-postal {
    padding-top: rem(8);
    padding-left: rem(30);
    padding-right: rem(30);
    display: flex;
    justify-content: space-between;
    h4 {
      margin: 0;
    }
    p {
      margin: 0;
      padding-top: rem(8);
    }
  }
  &__address {
    margin-right: rem(4);
  }
  &__contact {
    margin-left: rem(30);
    margin-top: rem(16);
    border-bottom: 1px solid $dark-grey;
    p {
      margin: rem(8) 0;
    }
    h4 {
      margin: 0;
    }
  }
  &__opening-hours {
    margin-left: rem(30);
    margin-right: rem(30);
    margin-top: rem(24);
    h4 {
      border-bottom: 1px solid $dark-grey;
      padding-bottom: rem(8);
      margin-bottom: 0;
    }
  }
  &__timings {
    li {
      border-bottom: 1px solid $dark-grey;
      padding: rem(8) 0;
    }
  }
  &__btn {
    .form-button {
      padding-top: rem(32);
    }
  }

  ul {
    list-style: none;
    li {
      display: flex;
      justify-content: space-between;
    }
  }
}
