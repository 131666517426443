//
// -------------------------------------
// 	USAGE
// -------------------------------------
//  some usefull information for developer to help describe
//  what following classes do.

$name: news-interests;
$selector: ".#{$name}";

.article-page a {
  color: blue;
}

#{$selector} {
  .btn--toggle {
    &:first-child {
      padding-top: 0;
    }
    &:nth-last-child(2) {
      padding-bottom: rem(162);
    }
  }
}
