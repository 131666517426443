.create-account {
  &__form-group {
    &--last-child {
      padding-bottom: $spacing;
      .input-form {
        padding-bottom: 0;
      }
      .error-message {
        padding-top: $spacing-xs;
      }
    }
  }
  &__bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    .android-keyboard-open & {
      position: relative;
    }

    .form-button {
      padding-bottom: $spacing-xxs;
    }
    p {
      margin-bottom: $spacing-l;
      span {
        display: inline-block;
        @extend p;
        text-transform: none;
        text-decoration: underline;
        color: $yellow;
      }
    }
  }
}
