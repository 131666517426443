@mixin floatLabel {
  top: rem(9);
  font-size: rem(12);
  font-weight: 400;
  line-height: rem(18);
  color: $dark-grey;
}

.input-form {
  padding: $spacing-xs $spacing-horizontal;
  position: relative;

  &__wrapper {
    background-color: $white;
    display: flex;
    align-items: center;
  }

  &__field,
  .floating-label {
    flex: 1;
  }

  .form-input {
    font-size: rem(20);
    font-weight: 400;
    border-radius: 0;
    line-height: rem(30);
    width: 100%;
    height: rem(54);
    padding: rem(14) rem(12) rem(8);
    border: none;
    color: $black;
    background: white;

    &:focus {
      outline: none;
    }

    &:focus ~ .form-input-label,
    :valid ~ .form-input-label {
      @include floatLabel();
    }

    &.invalid {
      border: 2px solid $error;
    }
  }

  textarea.form-input {
    height: auto;
  }

  // input[type="text"] {
  //   letter-spacing: 0.3em;
  // }

  input[type="date"] {
    position: relative;
    padding-top: rem($spacing-s);
    -webkit-appearance: none;
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }

    &:hover,
    &:focus {
      border: none;
    }
  }

  // Date picker, custom date icon
  .fa-calendar-alt {
    color: $black;
    font-size: rem(24);
    position: absolute;
    right: rem(42);
    top: rem(24);
    z-index: 1;
  }

  .form-input-label {
    font-size: rem(18);
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: rem(40);
    top: rem(20);
    transition: 300ms ease all;
    color: $label-grey;
    z-index: 1;

    &.float {
      @include floatLabel();
    }
  }

  //dropdown and date picker

  .form-input--select {
    appearance: none;
    flex: 1;
  }

  .fa-chevron-down {
    color: $black;
    font-size: rem(19);
    margin-right: rem(22);
  }

  &--counter {
    display: flex;
    .form-button {
      padding: 0;
      button {
        width: rem(64);
        height: rem(64);
      }
    }
    .input-form__wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    label {
      line-height: rem(18);
      font-size: rem(12);
      font-weight: normal;
      pointer-events: none;
      color: $black;
      padding-top: rem(4);
    }

    .form-input {
      text-align: center;
      font-size: rem(28);
      line-height: rem(42);
      padding: 0;
      height: auto;
    }
  }

  &--upload {
    display: flex;
    flex-direction: column;
    label {
      line-height: rem(18);
      font-size: rem(12);
      font-weight: normal;
      cursor: pointer;
    }
    span {
      line-height: rem(16);
      font-size: rem(12);
      font-weight: normal;
      color: $mid-grey;
      margin-bottom: rem(8);
    }
    input {
      display: none;
    }
    p {
      margin-bottom: rem(3);
    }
  }

  &--uploaded {
    &__images {
      margin-top: rem($spacing-xs);
      display: flex;
      flex-wrap: wrap;
      gap: 2px;
      > div {
        width: calc(50% - 2px);
        position: relative;
        button {
          position: absolute;
          width: rem(20);
          height: rem(20);
          background: $yellow;
          border: 0;
          border-radius: 50%;
          right: rem(4);
          top: rem(4);
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            color: $black;
            font-size: rem(14);
            margin-top: 1px;
          }
        }
        img {
          max-width: 100%;
          width: 100%;
          height: 100px;
        }
      }
    }
  }

  &__custom-file {
    background-color: $dark-grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    padding: rem(18);
    span {
      color: $yellow;
      font-size: rem(16);
      line-height: rem(18);
      margin-top: rem(8);
      font-weight: 600;
    }
  }
}

//submit button

.form-button {
  padding: $spacing-horizontal;

  &--sticky {
    padding: 0 $spacing-horizontal;
    position: fixed;
    width: 100%;
    bottom: rem(84);
    &::before {
      content: " ";
      background: linear-gradient(180deg, #000000 54.62%, rgba(0, 0, 0, 0) 100%);
      transform: rotate(180deg);
      width: 100%;
      position: absolute;
      height: rem(135);
      left: 0;
      top: rem(-54);
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.error-message {
  @extend .horizontal-spacing;
  color: $error;
  font-weight: 600;
  font-size: rem(13);
  line-height: rem(20);
  display: flex;
  align-items: center;
  gap: rem($spacing-xs);
  margin: -(rem($spacing-xxs)) 0 0 0;

  &--align-left {
    margin-top: rem($spacing-xs);
    justify-content: flex-start;
    padding: 0;
  }

  i {
    width: rem(18px);
    height: rem(18px);
    background: $error;
    color: $black;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: rem(10px);
  }
}
