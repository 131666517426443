$name: Toastify;
$selector: ".#{$name}";

#{$selector} {
  &__toast {
    background: $gradient-grey;
    color: $white;
    border-radius: 0;
    padding: rem($spacing-half-s) rem($spacing-s);
    min-height: auto;
    &-icon {
      align-self: flex-start;
      width: rem(20);
      height: rem(20);
      background: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        &::before {
          font-size: rem(14);
          color: $mid-grey;
        }
      }
    }
    &-body {
      padding: 0;
      margin: 0;
      align-items: baseline;
      h6 {
        margin: 0;
        line-height: rem(22);
      }
      p {
        margin: rem($spacing-xxs) 0 0 0;
      }
    }
    &-container {
      width: 100%;
      padding: rem(32) rem(16);
    }
    &.warn {
      #{$selector} {
        &__progress-bar {
          background: $gradient-yellow;
        }
      }
    }
    &.error {
      #{$selector} {
        &__progress-bar {
          background: $gradient-red;
        }
      }
    }
    &.warn,
    &.error {
      #{$selector} {
        &__toast-icon {
          i {
            align-self: flex-start;
            &::before {
              font-size: rem(12);
            }
          }
        }
      }
    }
  }
  &__close-button {
    opacity: 1;
    svg {
      width: rem(18);
      height: rem(22);
      fill: $white;
    }
  }
  &__progress-bar {
    height: rem(4);
    background: $gradient-green;
  }
}
