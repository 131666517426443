.auth-success {
  ul {
    list-style: none;
    li {
      display: flex;
      align-items: center;
      gap: rem($spacing-s);
      margin-bottom: rem($spacing-s);
      span {
        @include font-family("font-bold-secondary");
        width: rem(26);
        height: rem(26);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffffff;
        border-radius: 50%;
      }
      h4 {
        margin: 0;
      }
    }
  }
}

