.notification-settings__ {
  &description {
    margin-top: rem(8);
    margin-left: rem(30);
    margin-right: rem(66);
  }
  &toggle-btn {
    .btn {
      font-weight: 400;
    }
  }
}
