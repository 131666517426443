$name: store-detail;
$selector: ".#{$name}";

#{$selector} {
  margin: rem($spacing-half-s) rem($spacing-horizontal) rem($spacing-vertical);
  &__store-name {
    color: $ford-gray;
    h1 {
      font-family: inherit;
      font-size: rem(14);
      line-height: rem(22);
      margin-bottom: rem($spacing-xxs);
      text-transform: none;
    }
    i {
      margin-right: rem($spacing-xs);
    }
  }
  &__user-name {
    font-size: rem(32);
    line-height: rem(32);
    margin-bottom: rem($spacing);
  }
}
