.btn {
  text-align: center;
  padding: rem(18) rem(18);
  @include font-family("font-bold-third");
  font-size: rem(16);
  line-height: rem(18);
  border-radius: 0;
  background: none;
  display: inline-block;
  width: 100%;
  position: relative;
  border: 1px solid transparent;
  &--primary {
    background: $yellow;
    color: $black;
    &-black {
      background: $black;
      color: $white;
    }
  }

  &--secondary,
  &--toggle-handler {
    color: $white;
    border: 1px solid $white;
  }

  &--secondary--yellow {
    color: $yellow;
    border: 1px solid $yellow;
  }

  &--toggle {
    padding: rem($spacing-xs) rem($spacing-m);
    &--no-padding-top {
      padding-top: 0;
    }
    &-handler {
      position: relative;
      display: inline-flex;
      align-items: center;
      text-align: start;
      cursor: pointer;
      width: 100%;
      &::before {
        content: " ";
        margin: 0 rem($spacing-vertical-m) 0 0;
        min-width: rem(28);
        min-height: rem(28);
        border-radius: 50%;
        border: 2px solid $yellow;
      }
    }
    input {
      &:checked {
        + label {
          color: $yellow;
          border-color: $yellow;
          &::before {
            background: $yellow;
          }
          &::after {
            content: "\f00c";
            @extend .fas;
            font-size: rem(16);
            color: $black;
            position: absolute;
            left: rem(24);
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  i {
    margin: 0 0 0 rem($spacing-xs);
  }

  &--disabled,
  &:disabled {
    background: $light-grey;
    color: $mid-grey;
  }
}

.add-btn {
  border: none;
  margin: 0;
  background-color: $yellow;
  padding: 0;
  width: rem(32);
  height: rem(32);
  color: $black;
  border-radius: 100%;
}


.add-photo-btn {
  @extend .add-btn;
  text-align: center;
  padding: rem(8) rem(10);
  width: auto;
  height: auto;
  i {
    color: $black;
    font-size: rem(19);
  }
}
