//
// -------------------------------------
// 	USAGE
// -------------------------------------
//  some usefull information for developer to help describe
//  what following classes do.

$name: trade-card;
$selector: ".#{$name}";

#{$selector} {
  &__img {
    height: rem(176);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }
  }
  &__content {
    padding: 0 rem($spacing-s) 0;
    margin: rem(-$spacing-xxl) 0 0 0;
  }
  &__headings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h3 {
      margin: 0;
      display: flex;
      flex-direction: column;
      span {
        align-self: flex-start;
      }
    }
  }
  &__headings,
  &__description {
    margin: 0 0 rem(8) 0;
  }
}
