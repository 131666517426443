// helper classes
.hidden {
  display: none !important;
}

.disabled {
  pointer-events: none;
}

.invisible {
  visibility: hidden !important;
}

.no-gutter {
  padding: 0 !important;
  margin: 0 !important;
}

.h-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.is-relative {
  position: relative;
}

// table
.tabular {
  display: table;

  .tabular--cell {
    display: table-cell;
    vertical-align: middle;
  }
}

.tabular--full-width {
  width: 100%;
}

.tabular--table-layout {
  table-layout: fixed;
}

.tabular--align-top {
  .tabular--cell {
    vertical-align: top;
  }
}

.tabular--align-bottom {
  .tabular--cell {
    vertical-align: bottom;
  }
}

// Screen-reader only.
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  margin: -1px;
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  margin: 0;
  overflow: visible;
  position: static;
}

.sr-skip-main:focus {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  text-align: center;
}

// Remove the top margin from the first child element.
.flat-top {
  > *:first-child {
    margin-top: 0;
  }
}

// Remove the bottom margin from the last child element.
.flat-bottom {
  > *:last-child {
    margin-bottom: 0;
  }
}

// Force-remove the top padding.
.no-padding-top {
  padding-top: 0 !important;
}

// Force-remove the bottom padding.
.no-padding-bottom {
  padding-bottom: 0 !important;
}

.clearfix {
  //@include clearfix;
}

// Responsive embeds
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  &-item,
  iframe,
  embed,
  object {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.494%;
}

.template {
  display: none;
}

.element--centered {
  margin: 0 auto;
}

.max-width-sm {
  max-width: 570px;
}

.text--strong {
  font-weight: 700;
}

.-bg-image {
  background-position: center center;
  background-size: cover;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.show {
  visibility: visible !important;
  opacity: 1 !important;
}

.bold {
  @include font-family("font-bold-third");
}

.italic {
  @include font-family("font-italic");
}

.index-page-logo {
  width: 100px;
  height: 100px;
}

.bg-black {
  color: $white;
  padding: rem(10);
  background: $black;
}

.slick-slide {
  outline: none;
}

.z-index-one {
  position: relative;
  z-index: 1;
}

.white-highlight,
.yellow-highlight,
.black-highlight {
  display: inline-block;
  padding: rem(4);
}

.black-highlight {
  background: $black;
  color: $white;
}

.yellow-highlight {
  background: $yellow;
  color: $black;
}

.white-highlight {
  background: $white;
  color: $black;
}

.transform-none {
  text-transform: none;
}

.horizontal-spacing {
  padding-left: $spacing-horizontal;
  padding-right: $spacing-horizontal;
}

.two-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.sticky-bottom {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  .android-keyboard-open & {
    position: relative;
  }

  &__link {
    display: inline-block;
    width: 100%;
    margin: rem(2) 0 rem($spacing-l) 0;

    p {
      span {
        &:not(.text--bold) {
          font-weight: normal;
          display: inline-block;
          margin-right: rem($spacing-xxs);
        }
      }
    }
  }
}

.h-100 {
  height: 100%;
}

// spacings
.u-margin-top-xs {
  margin-top: $spacing-xs;
}

.u-margin-top {
  margin-top: $spacing;
}

.u-margin-top-auto {
  margin-top: auto;
}

.break-all {
  word-break: break-all;
}

.m-0 {
  margin: 0;
}

