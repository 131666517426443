.document-library-search {
  padding: 0 rem($spacing-horizontal) rem($spacing-vertical);

  &__wrapper {
    position: relative;
  }

  .fa-search,
  .fa-times {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: rem(20);
  }

  .fa-search {
    left: rem($spacing-s);
  }

  .fa-times {
    right: rem($spacing-half-s);
    color: $light-grey;
  }

  input {
    box-sizing: border-box;
    height: rem(45);
    background-color: rgba(255, 255, 255, 0.1);
    color: $white;
    padding-left: rem(42);
    padding-right: rem($spacing-xs);
    font-size: rem(12);
    line-height: rem(17);
    border: none;
    border-radius: 0;
    width: 100%;
    -webkit-appearance: none;
  }
}
