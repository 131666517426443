.article-page {
  .head-page {
    &__title {
      padding-bottom: rem(8);
    }
  }
  &__info {
    margin-bottom: rem(24);
  }
}
