//
// -------------------------------------
// 	USAGE
// -------------------------------------
//  some usefull information for developer to help describe
//  what following classes do.

$name: carousel-card;
$selector: ".#{$name}";

#{$selector} {
  &__wrapper {
    background: $dark-grey;
    padding: rem(14) rem($spacing-s) rem($spacing-s);
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-bottom: 46px solid $yellow;
      border-left: 26px solid transparent;
    }
  }
  &__heading {
    p {
      margin: 0 0 rem(14) 0;
    }
  }
  &__img {
    height: 158px;
    margin: 0 rem(-($spacing-s)) rem($spacing-s);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(0deg, rgba(12, 12, 12, 0.05), rgba(12, 12, 12, 0.05));
    }
  }
  &__date {
    p {
      margin: 0 0 rem($spacing-xs) 0;
      color: $light-grey;
    }
  }
  &__subheading {
    h4 {
      margin: 0;
    }
  }
}
