.documents-library-feature {
  padding-bottom: $spacing-vertical;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $spacing-horizontal $spacing-vertical-m;
    &__title {
      font-family: $font-regular;
      font-size: rem(16);
      line-height: rem(19);
      color: $white;
      text-transform: none;
    }
    * {
      margin: 0;
    }
  }
  &__tiles {
    display: flex;

    // for horizontal scrolling
    &--horizontal {
      overflow-x: auto;
      & > :first-child {
        margin-left: $spacing-horizontal;
      }
      & > * {
        margin-right: $spacing-xs;
      }
    }
  }

  &--stack {
    .documents-library-feature__tiles {
      flex-direction: column;
      padding-right: $spacing-horizontal;
      padding-left: $spacing-horizontal;
      & > * {
        margin-bottom: $spacing-xs;
      }
    }
  }

  &__info-message {
    text-align: center;
    margin: 0;
    padding: 0 $spacing-horizontal;
  }

  &--search {
    .document-library-card__favourite {
      display: none;
    }
    &__no-result {
      margin-top: rem($spacing-xs);
      padding: 0 rem(30);
      text-align: center;
      color: $orange;

      .fa-exclamation {
        font-size: rem(50);
        width: rem(80);
        height: rem(80);
        margin: 0 auto;
        background: $orange;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black;
        border-radius: 50%;
        margin-bottom: rem($spacing-half-s);
      }
    }
  }
}
