// Spacing

$spacing-xxs : 4px;
$spacing-xs  : 8px;
$spacing-s   : 16px;
$spacing     : 24px;
$spacing-half-s: 12px;
$spacing-m   : 32px;
$spacing-l   : 40px;
$spacing-half-l   : $spacing-l * 0.5;
$spacing-xl  : 48px;
$spacing-xxl : 64px;
$spacing-3xl : 128px;


$spacing-horizontal: 30px;
$spacing-vertical: $spacing;
$spacing-vertical-m: $spacing-s;
$spacing-vertical-s: $spacing-half-s;


$font-regular: Graphik, Helvetica, Arial, sans-serif;
$font-eurostile: Eurostile-CondensedBold, Helvetica, Arial, sans-serif;
