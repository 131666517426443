input,
textarea,
keygen,
select,
button {
  font-size: inherit;
}

label {
  white-space: normal;
}

input:invalid,
button:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

input:focus,
button:focus,
select:focus,
textarea:focus {
  z-index: 1;
}

input[type="file"]:focus,
input[type="file"]:active,
input[type="radio"]:focus,
input[type="radio"]:active,
input[type="checkbox"]:focus,
input[type="checkbox"]:active {
  box-shadow: none;
}

button,
input[type="reset"],
input[type="submit"],
input[type="button"] {
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 0 16px;
  text-decoration: none;
  vertical-align: top;
}

button:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
  text-decoration: none;
}

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  box-shadow: none;
  display: block;
  margin: 0;
  text-align: left;
  vertical-align: top;
  width: 100%;
  border-radius: 0;
  padding: 16px 0 16px 14px;
  height: 64px;
  color: $black;
  font-size: 16px;
  line-height: 1.81;
  border: solid 1.5px transparent;
  outline: none;
}

button[disabled],
input[disabled],
select[disabled],
select[disabled] option,
select[disabled] optgroup,
textarea[disabled] {
  box-shadow: none;
  cursor: default;
}

textarea,
select[size],
select[multiple] {
  height: auto;
}

select[size="0"],
select[size="1"] {
  height: 40px;
}

textarea {
  min-height: 40px;
  overflow: auto;
  resize: vertical;
}

optgroup {
  font-style: normal;
  font-weight: normal;
}
