.lbp-details {
  &__description {
    margin-top: rem(24);
    margin-left: rem(30);
    margin-right: rem(66);
  }
  &__input {
    margin-top: rem($spacing-xxs);
  }
}
