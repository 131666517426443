.activity-log-feature {
  padding: rem(8) $spacing-horizontal rem(16);
  > div {
    &:first-child {
      .activity-log-feature__title {
        margin: 0;
      }
    }
  }
  &__title {
    font-size: rem(12);
    line-height: rem(14);
    font-family: $font-regular;
    margin: rem($spacing) 0 0 0;
  }

  &--no-logs {
    display: flex;
    flex-direction: column;
    p {
      margin-top: rem(24);
      margin-left: $spacing-horizontal;
    }
    padding: 0;
    .form-button {
      position: absolute;
      width: 100%;
      bottom: rem(60);
    }
  }
  &__export {
    height: rem(120);
  }
}
