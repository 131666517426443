// Site colours.

$yellow: #fff200;
$white: #ffffff;
$black: #000000;
$x-dark-grey: #0c0c0c;
$eerie-black: #151515;
$dark-grey: #333333;
$mid-grey: #5d5d5d;
$mild-grey: #5c5c5c;
$light-grey: #e4e4e4;
$transparent-grey: rgba(255, 255, 255, 0.6);
$transparent-white: rgba(255, 255, 255, 0.9);
$snow: #f7f5f0;
$gray-disabled: #757575;
$ford-gray: #979797;
$transparent: rgba(0, 0, 0, 0);
$mid-red: #ff6b6c;
$red:#ff0000;
$blue: #2a8bfe;
$green: #1da483;
$light-green: #4cd964;
$light-red: #ff4f00;
$gradient-green: linear-gradient(0deg, #4cd964, #4cd964), $white;
$gradient-red: linear-gradient(0deg, #ff0000, #ff0000), $white;
$gradient-yellow: linear-gradient(0deg, #ffbd00, #ffbd00), $white;
$gradient-grey: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  $white;
$gradient-mild-grey: linear-gradient(155.26deg, $dark-grey 8.34%, $eerie-black 105.64%);

$mid-red: #ff6b6c;
$blue: #2a8bfe;
$green: #1da483;
$mild-grey:#5c5c5c;
$light-shade:#bdbdbd;
$orange: #ff9c00;
$error: #eb5757;
$label-grey: #555555;
