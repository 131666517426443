.account-details {
  &__save-btn {
    .form-button {
      padding-top: rem(24);
    }
  }
  &__toggle-btn {
    .btn {
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(22);
      padding: 0;
      margin-top: rem(16);
    }
  }
  .error-message {
    @extend .horizontal-spacing;
    color: $red;
    font-weight: 600;
    font-size: rem(13);
    line-height: rem(20);
    display: flex;
    align-items: center;
    gap: rem($spacing-xs);
    margin: -(rem($spacing-xxs)) 0 0 0;

    i {
      width: rem(18px);
      height: rem(18px);
      background: $red;
      color: $black;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: rem(10px);
    }
  }
  .btn--secondary,
  .btn--toggle-handler {
    color: $white;
    border: 0;
  }
  .btn--toggle input:checked + label {
    color: $white;
    border: none;
  }
  .btn--toggle input:checked + label::after {
    content: "\f00c";
    font-size: 1rem;
    color: #000000;
    position: absolute;
    left: rem(6);
    top: rem(8);
  }
}
