$name: card-slider;
$selector: ".#{$name}";

#{$selector} {
  padding: rem($spacing-s) 0 rem($spacing-xl);
  .slick {
    &-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: rem(16);
      ul {
        margin: 0;
        padding: 0;
        height: rem(3);
        list-style: none;
        display: flex;
        width: rem(145);
        background: $mild-grey;
        li {
          background: transparent;
          transition: all 0.3s;
          &.slick-active {
            background: $light-grey;
          }
        }
        button {
          display: none;
        }
      }
    }

    &-slide {
      transform: scale(0.85);
      transition: transform 0.2s;
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        inset: 0;
        z-index: 1;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        transition: all 0.2s;
      }
      &.slick-center {
        transform: scale(1);
        &::before {
          background: none;
          z-index: 0;
        }
      }
      > div {
        width: rem(264);
      }
    }
  }

  &__controls {
    &--next,
    &--prev {
      @include font-family("font-bold-third");
      font-weight: bold;
      font-size: rem(18);
      line-height: rem(22);
      i {
        &::before {
          font-size: rem(20);
        }
      }
    }
    &--next {
      margin-right: rem($spacing-xs);
      i {
        margin-right: rem($spacing-xs);
      }
    }
    &--prev {
      margin-left: rem($spacing-xs);
      i {
        margin-left: rem($spacing-xs);
      }
    }
  }

  // Slider progress bg fill
  ._2 {
    li {
      @include sliderProgressFill(2);
    }
  }

  ._3 {
    li {
      @include sliderProgressFill(3);
    }
  }

  ._4 {
    li {
      @include sliderProgressFill(4);
    }
  }

  ._5 {
    li {
      @include sliderProgressFill(5);
    }
  }

  ._6 {
    li {
      @include sliderProgressFill(6);
    }
  }

  ._7 {
    li {
      @include sliderProgressFill(7);
    }
  }
  ._8 {
    li {
      @include sliderProgressFill(8);
    }
  }
  ._9 {
    li {
      @include sliderProgressFill(9);
    }
  }
  ._10 {
    li {
      @include sliderProgressFill(10);
    }
  }
  ._11 {
    li {
      @include sliderProgressFill(11);
    }
  }
  ._12 {
    li {
      @include sliderProgressFill(12);
    }
  }
  ._13 {
    li {
      @include sliderProgressFill(13);
    }
  }
  ._14 {
    li {
      @include sliderProgressFill(14);
    }
  }
  ._15 {
    li {
      @include sliderProgressFill(15);
    }
  }
  ._16 {
    li {
      @include sliderProgressFill(16);
    }
  }
  ._17 {
    li {
      @include sliderProgressFill(17);
    }
  }
  ._18 {
    li {
      @include sliderProgressFill(18);
    }
  }
  ._19 {
    li {
      @include sliderProgressFill(19);
    }
  }
  ._20 {
    li {
      @include sliderProgressFill(20);
    }
  }
}
