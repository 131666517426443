.sticky-navigation {
  width: 100%;
  padding-top: rem(60);

  &__wrapper {
    position: fixed;
    z-index: 2;
    background-color: $dark-grey;
    bottom: 0;
    width: 100%;
    height: rem(60);
    display: flex;
    justify-content: center;
  }

  &__list {
    display: flex;
    justify-content: center;
    height: 100%;
    list-style: none;
    width: 100%;
    margin: 0 rem($spacing-xs);
    gap: rem($spacing-xxs);
  }

  &__item {
    max-width: rem(90);
    width: calc(100% / 4);
    height: 100%;
    position: relative;
    &.active {
      a {
        color: $yellow;
        &::after {
          background-color: $yellow;
        }
      }
    }
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      line-height: rem(12);
      font-weight: normal;
      text-transform: capitalize;
      &::after {
        content: " ";
        position: absolute;
        top: 0;
        width: 100%;
        height: 4px;
      }
      i {
        font-size: rem(20);
        color: inherit;
        margin-bottom: rem($spacing-xs);
      }
    }
  }
}
