$name: prompt-card;
$selector: ".#{$name}";

#{$selector} {
  margin: 0 rem($spacing-horizontal) rem($spacing-xl);
  background: $gradient-mild-grey;
  &__wrapper {
    position: relative;
    padding: rem($spacing-s) rem($spacing-s) rem($spacing);
    h3 {
      margin-bottom: rem($spacing-xs);
    }
    p {
      margin: 0;
      max-width: rem(195);
    }
  }
  &__close-btn,
  &__arrow-right {
    background: none;
    border: 0;
    padding: 0;
    position: absolute;
    right: rem($spacing-half-s);
    z-index: 1;
  }
  &__close-btn {
    top: rem($spacing-s);
    i {
      &::before {
        font-size: rem(20);
      }
    }
  }

  &__next-btn {
    border: 0;
    background: transparent;
    color: $black;
    font-family: rem(16);
    width: rem(57);
    height: rem(99);
    position: absolute;
    right: 0;
    bottom: 0;
    &__backdrop {
      position: absolute;
      bottom: 0;
      right: 0;
      @include corner-shape(57, 99, $yellow);
    }

    i {
      position: absolute;
      bottom: rem(11);
      right: rem(11);
    }
  }
}
