.locate-itm {
  &__description {
    margin: 0 rem(30);
    margin-top: rem(8);
    p {
      margin: 0;
    }
  }
  &__locate-store {
    position: relative;
    margin-top: rem(24);
    div {
      margin: 0 rem(30);

      input {
        background: rgba(255, 255, 255, 0.1);
        height: rem(54);
        padding-left: rem(42);
        color: $white;
      }
      margin-bottom: rem(16);
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: rem($spacing-xs);
  }
  &__store-list {
    margin: 0 rem(30);
    border-top: 1px solid $dark-grey;

    ul {
      margin-top: rem(16);
      list-style: none;
      h4 {
        margin: 0;
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(19);
      }
      p {
        margin: 0;
        font-size: rem(12);
        font-weight: 400;
        line-height: rem(16);
      }
    }
  }
  &__heading {
    .head-page__back-btn {
      padding-bottom: rem(24);
    }
  }
  &__link {
    display: block;
    margin-bottom: rem(16);
    border-bottom: 1px solid $dark-grey;
    p {
      padding-bottom: rem(16);
    }
  }
}
