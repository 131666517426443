/* Specific heading styles. */
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  text-transform: uppercase;
}

h1,
.h1 {
  @include font-family("font-bold-secondary");
  font-size: rem(48);
  line-height: rem(47);
  margin: 0 0 16px 0;
}

h2,
.h2 {
  @include font-family("font-bold-secondary");
  margin: 0 0 16px 0;
  font-size: rem(38);
  line-height: rem(37);
}

h3,
.h3 {
  @include font-family("font-bold-secondary");
  font-size: rem(28);
  line-height: rem(28);
  margin: 0 0 16px 0;
}

h4,
.h4 {
  @include font-family("font-bold-third");
  margin: 0 0 16px 0;
  font-size: rem(16);
  line-height: rem(19);
}

h5,
.h5 {
  @include font-family("font-bold-third");
  margin: 0 0 16px 0;
  font-size: rem(16);
  line-height: rem(19);
}

h6,
.h6 {
  @include font-family("font-bold-third");
  margin: 0 0 16px 0;
  font-size: rem(16);
  line-height: rem(19);
}

caption,
.caption {
  @include font-family("font-third");
  font-size: rem(14);
  line-height: 1.57;
  text-align: left;
  margin: 4px 0 0 0;
}

// Links.
a,
.tag {
  @include font-family("font-bold-third");
  text-decoration: none;
  color: $white;
  font-size: rem(12);
  line-height: rem(14);
  text-transform: uppercase;
  &:focus,
  &:hover {
    text-decoration: none;
  }
  &.default-link {
    color: -webkit-link;
    text-decoration: underline;
  }
}

p,
.p {
  @include font-family("font-third");
  font-size: rem(16);
  line-height: rem(22);
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

p.small-text {
  @include font-family("font-third");
  font-size: rem(12);
  line-height: rem(17);
  &--alt {
    margin-bottom: rem($spacing-xxs);
    line-height: rem(18);
    letter-spacing: rem(0.25);
  }
}

// Lists.
ul,
ol {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.56;

  > li {
    font-size: 16px;
    line-height: 1.56;
  }
}

sub,
sup {
  font-size: 10px;
}

// Blockquotes.
blockquote {
  @include font-family("font-third");
  margin-left: rem(-$spacing-horizontal);
  margin-right: rem(-$spacing-horizontal);
  padding: 24px $spacing-horizontal;
  background-color: $yellow;
  text-align: left;
  margin-bottom: rem($spacing-s);
}

// Quote
q {
  @include font-family("font-italic");
}

// Text Modifiers
.text-sz-10 {
  font-size: rem(10);
}

.text-sz-12 {
  font-size: rem(12);
}

.text-sz-14 {
  font-size: rem(14);
}

.text-sz-16 {
  font-size: rem(16);
}

.text-sz-18 {
  font-size: rem(18);
}

.text-sz-20 {
  font-size: rem(20);
}

.text-sz-24 {
  font-size: rem(24);
}

.text-sz-32 {
  font-size: rem(32);
}

.text-sz-40 {
  font-size: rem(40);
}

.text-sz-48 {
  font-size: rem(48);
}

.text-sz-56 {
  font-size: rem(56);
}

.text-sz-64 {
  font-size: rem(64);
}

.text--p {
  @include font-family("font-normal");
}

.text--line-through {
  text-decoration: line-through;
}

.text--underline {
  text-decoration: underline;
}

.text--italic {
  @include font-family("font-italic");
}

.text--quote {
  @include font-family("font-italic");
}

.text--centered {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--tertiary {
  @include font-family("font-secondary");
}

.text--special {
  @include font-family("font-secondary");
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.14;
  margin-bottom: 2rem;
}

.text--bold {
  @include font-family("font-bold-third");
}

.text--uppercase {
  text-transform: uppercase;
}

.text--capitalize {
  text-transform: capitalize;
}
